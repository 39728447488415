html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.imported-restaurants-table-item-with-error {
  color: var(--chakra-colors-red-500);
}

.imported-restaurants-table-row-with-error {
  background-color: var(--chakra-colors-red-100) !important;
}

.imported-restaurants-table-column-with-error-text {
  color: var(--chakra-colors-red-500);
}
