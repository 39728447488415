@media print {
  @page {
    margin:0;
  }

}

@page {
  size: A4;
}

@media screen {
  div.reportsHeader {
    display: none;
  }
}

* {
  color-adjust: exact;
}
