.react-date-picker {
  width: 100%;
}

.react-date-picker__wrapper {
  font-family: 'Rubik';
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);
  min-height: 38px;
}
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
.react-date-picker svg {
  stroke: hsl(0, 0%, 45%);
  stroke-width: 2;
}

.react-date-picker__calendar .react-calendar {
  font-family: 'Rubik';
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

.react-calendar__tile--active {
  background: #ffb300;
  color: white;
}

.react-calendar__tile--now {
  background: white;
  font-weight: bold;
}
